/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import PricingSectionStiloso from '@stiloso/sections/PricingSection.js'
import { useTranslation } from '@multilocale/react/index.js'

const PricingSection = ({ locale }) => {
  const { t } = useTranslation(locale)

  return (
    <PricingSectionStiloso
      locale={locale}
      plans={[
        {
          amount: 0,
          billingFrequency: 'MONTHLY',
          currencyCode: 'USD',
          name: 'Free',
          lines: [
            t('{storage_count} storage').replace('{storage_count}', '20GB'),
            t('{bandwidth_count} bandwidth').replace(
              '{bandwidth_count}',
              '20GB',
            ),
          ],
        },
        {
          amount: 19,
          billingFrequency: 'MONTHLY',
          currencyCode: 'USD',
          name: 'Basic',
          lines: [
            t('{storage_count} storage').replace('{storage_count}', '40GB'),
            t('{bandwidth_count} bandwidth').replace(
              '{bandwidth_count}',
              '40GB',
            ),
          ],
        },
        {
          amount: 49,
          billingFrequency: 'MONTHLY',
          currencyCode: 'USD',
          name: 'Plus',
          lines: [
            t('{storage_count} storage').replace('{storage_count}', '100GB'),
            t('{bandwidth_count} bandwidth').replace(
              '{bandwidth_count}',
              '100GB',
            ),
          ],
        },
        {
          amount: 89,
          billingFrequency: 'MONTHLY',
          currencyCode: 'USD',
          name: 'Pro',
          lines: [
            t('{storage_count} storage').replace('{storage_count}', '1000GB'),
            t('{bandwidth_count} bandwidth').replace(
              '{bandwidth_count}',
              '1000GB',
            ),
          ],
        },
      ]}
    />
  )
}

export default PricingSection
