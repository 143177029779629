/* Copyright 2013 - 2024 Waiterio LLC */
import IS_DEVELOPMENT from './IS_DEVELOPMENT.js'
import IS_STAGING from './IS_STAGING.js'
import IS_TESTING from './IS_TESTING.js'
import IS_BROWSER from './IS_BROWSER.js'
import IS_NODE from './IS_NODE.js'

export default function getImagelatoAppUrl() {
  let url = 'https://app.imagelato.com'

  if (IS_BROWSER && window.IMAGELATO_APP_URL === '{{{IMAGELATO_APP_URL}}}') {
    delete window.IMAGELATO_APP_URL
  }

  if (IS_NODE && process.env.IMAGELATO_APP_URL === '{{{IMAGELATO_APP_URL}}}') {
    delete process.env.IMAGELATO_APP_URL
  }

  if (IS_BROWSER && window.IMAGELATO_APP_URL) {
    url = `${window.IMAGELATO_APP_URL}`
  } else if (IS_NODE && process.env.IMAGELATO_APP_URL) {
    url = process.env.IMAGELATO_APP_URL
  } else if (IS_STAGING) {
    url = 'https://app.imagelato-staging.com'
  } else if (IS_DEVELOPMENT || IS_TESTING) {
    // url = 'https://app.imagelato-staging.com';
    // url = 'http://192.168.1.64:4141';
    url = 'http://localhost:4141'
  }

  // if (IS_STAGING || IS_DEVELOPMENT) {
  //   console.debug('Imagelato.url = ' + url)
  // }

  return url
}
